
import { defineComponent, onMounted, ref } from "vue";
import ApiService from "@/core/services/ApiService";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "add-office-modal",
  props: {
    modalName: {
      type: String,
      default: "none",
    },
    modalTitle: {
      type: String,
      default: "none",
    },
  },
  components: {},
  setup() {
    const store = useStore();
    const route = useRoute();
    const elements = ref({
      loading: false,
      options: [],
      list: [],
    }) as any;
    const addContacts = (all: boolean) => {
      users.forEach(function (value) {
        //
      });
      if (all) {
        //
      } else {
        //
      }
    };

    onMounted(() => {
      ApiService.get("/api/offices").then(function (response) {
        response.data.offices.forEach(function (value) {
          users.value.push({
            id: value.id,
            name: value.name,
            selected: false,
          });
        });
      });
    });
    const users = ref([]) as any;

    return {
      users,
    };
  },
});
